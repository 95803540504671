export default {
  SET_COOKIE_CONTENT: (state, payload) => {
    state.cookieContent = payload;
  },

  SET_PRIVACY_CONTENT: (state, payload) => {
    state.privacyContent = payload;
  },

  SET_TERMS_CONTENT: (state, payload) => {
    state.termsContent = payload;
  },
};
