import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const productsModule = {
  state() {
    return {
      product: {},
      products: null,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
