export default {
  cookieContent: (state) => {
    return state.cookieContent;
  },

  privacyContent: (state) => {
    return state.privacyContent;
  },

  termsContent: (state) => {
    return state.termsContent;
  },
};
