export default {
  settings: (state) => state.settings,

  brandLogoUrl: (state) =>
    state.settings ? state.settings.brandLogoUrl : require('../../assets/images/brandFallbackLogo.png'),
  retailerLogoUrl: (state) =>
    state.settings ? state.settings.retailerLogoUrl : require('../../assets/images/retailerFallbackLogo.png'),
  keyVisualUrl: (state) =>
    state.settings ? state.settings.keyVisualUrl : require('../../assets/images/retailerFallbackLogo.png'),

  leafletUrl: (state) => (state.settings ? state.settings.leafletUrl : ''),

  isFirstNameVisible: (state) => state.settings.isFirstNameVisible,
  isFirstNameRequired: (state) => state.settings.isFirstNameRequired,

  isLastNameVisible: (state) => state.settings.isLastNameVisible,
  isLastNameRequired: (state) => state.settings.isLastNameRequired,

  isPhoneVisible: (state) => state.settings.isPhoneVisible,
  isPhoneRequired: (state) => state.settings.isPhoneRequired,

  isMobileVisible: (state) => state.settings.isMobileVisible,
  isMobileRequired: (state) => state.settings.isMobileRequired,

  isLoyaltyCardVisible: (state) => state.settings.isLoyaltyCardVisible,
  isLoyaltyCardRequired: (state) => state.settings.isLoyaltyCardRequired,

  isDigitalCollectorVisible: (state) => state.settings.isDigitalCollectorVisible,
  isDigitalCollectorRequired: (state) => state.settings.isDigitalCollectorRequired,

  isVoucherCodeRequired: (state) => state.settings.isVoucherCodeRequired,

  productHasCoupon: (state) => state.settings.productHasCoupon,

  programStep: (state) => state.settings.programStep,
  grantConsent: (state) => state.grantConsent,
};
