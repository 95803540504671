<template>
  <div
    class="backdrop flex justify-center align-center"
    @click="closeModal()"
  >
    <section class="popup flex flex-column justify-center align-center">
      <h2 class="title">{{ title }}</h2>
      <p class="info">{{ text }}</p>
      <button
        @click="closeModal()"
        class="btn btn-primary alternative"
      >
        Ok
      </button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'WarningMessageModal',
  props: ['title', 'text'],
  methods: {
    closeModal() {
      this.$store.commit('CLEAR_ERRORS');
      this.$emit('closeModal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.backdrop {
  background-color: rgba($black, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.popup {
  background-color: $white;
  border-radius: 0.8rem;
  padding: 5rem 10rem;
  max-width: 80rem;
}

.title {
  font-size: 3rem;
  margin-bottom: 2rem;
}

.info {
  font-size: 1.8rem;
  margin-bottom: 5rem;
}
</style>
