import requests from '../requests';

const products = {
  all: () => requests.get('/products'),
  details: (id) => requests.get(`/products/${id}`),
  addNotification: (subscription) => requests.post('/products/addnotification', subscription),
  removeNotification: (email) => requests.del('/products/removenotification', { data: { email } }),
};

export default products;
