import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const storesModule = {
  state() {
    return {
      stores: [],
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
