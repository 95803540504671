import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const textsModule = {
  state() {
    return {
      texts: null,
      homeText: null,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
