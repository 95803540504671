import requests from '../requests';

const users = {
  login: (credentials) => requests.post('/users/login', credentials),
  register: (user) => requests.post('/users/register', user),
  forgotPassword: (email) => requests.post('/users/forgotpassword', email),
  resetPassword: (credentials) => requests.post('/users/resetpassword', credentials),
  changePassword: (credentials) => requests.post('/users/changepassword', credentials),
  userDetails: (id) => requests.get(`/users/${id}`),
  updateUser: (user) => requests.put('/users/updateuser', user),
  removeUser: () => requests.del('/users/removeuser'),
};

export default users;
