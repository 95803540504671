<template>
  <div
    class="col-4-12 md-col-1-2 xs-col-1-1 flex"
    :class="{ 'not-available': product.inStock === 2 }"
  >
    <router-link
      :to="`/product-catalog/${product.id}`"
      class="product product-homepage flex"
    >
      <div
        v-if="product.inStock === 2"
        class="not-available"
      >
        {{ notAvailable }}
      </div>
      <div class="product-top">
        <div class="product-image">
          <img :src="product.imageUrl" />
        </div>
        <h3 class="title">{{ product[`name${selectedLanguage}`] }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductOverviewCard',
  props: {
    product: Object,
  },
  computed: {
    ...mapGetters(['selectedLanguage', 'notAvailable']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.not-available .product {
  position: relative;
  pointer-events: none;
}

.product .not-available {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.6);
  color: $white;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  // z-index: 3;
}

.product {
  background-color: $white;
  border-radius: 0.8rem;
  box-shadow: $shadow-1;
  overflow: hidden;
  margin: 1.5rem 0;
  width: 100%;
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
}

.product-top {
  padding: 1.5rem 3rem;
}

.product-image {
  margin-bottom: 1.5rem;
}

.product-image img {
  width: 100%;
  max-width: 100%;
  display: block;

  .title {
    font-size: 2.4rem;
    font-weight: 400;
    margin: 0 0 2rem 0;
    text-align: center;
  }
}

.product-homepage .product-top .title {
  text-align: left;
}
</style>
