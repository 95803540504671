import agent from '../../api/agent';
import router from '../../router/index';
import { parseJwt } from '../../utils/parsers';

export default {
  login: async ({ commit }, credentials) => {
    try {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }

      const { token } = await agent.users.login(credentials);
      localStorage.setItem('token', token);
      const parsedToken = parseJwt(token);

      commit('SET_ORDER_ID', parsedToken.orderId);
      commit('SET_USER_ID', parsedToken.userId);
      commit('SET_ROLE', parsedToken.role);
      commit('SET_STORE_ID', parsedToken.storeId);
      commit('SET_AUTHENTICATED', true);
      commit('SET_TOKEN_IS_EXPIRED', false);
      return parsedToken;
    } catch (error) {
      console.error(error);
    }
  },

  autoLogin: ({ commit }, token) => {
    const { userId, orderId, exp } = parseJwt(token);
    const expDate = exp * 1000;
    // console.log('Remaining time: ' +  ((new Date(expDate).getTime() - new Date().getTime()) / 1000 / 60).toFixed() + ' minutes');
    const tokenIsExpired = expDate < Date.now();
    if (tokenIsExpired) {
      localStorage.removeItem('token');
      router.push('/login');
    } else {
      commit('SET_ORDER_ID', orderId);
      commit('SET_USER_ID', userId);
      commit('SET_AUTHENTICATED', true);
      commit('SET_TOKEN_IS_EXPIRED', false);

      const queryString = window.location.href.split('?')[1];

      if (queryString) {
        const queries = queryString.split('&');
        if (queries.length) {
          queries.forEach((q) => {
            if (q === 'redirect=my-account') {
              router.push({ path: '/my-account' });
            }
          });
        }
      } else {
        router.push(`/my-order/${orderId}`);
      }
    }
  },

  logout: ({ commit }) => {
    localStorage.removeItem('token');
    commit('SET_USER_ID', null);
    commit('REMOVE_USER');
    commit('SET_ORDER', null);
    commit('SET_ORDER_ID', null);
    commit('SET_STORE_ID', null);
    commit('SET_AUTHENTICATED', false);
    router.push('/');
  },

  updateUserDetails: async ({ commit }, userDetails) => {
    try {
      await agent.users.updateUser(userDetails);
      commit('SET_USER_DETAILS', userDetails);
    } catch (error) {
      console.error(error);
    }
  },

  removeUser: async ({ commit }) => {
    try {
      await agent.users.removeUser();
      localStorage.removeItem('token');
      commit('REMOVE_USER');
      commit('SET_ORDER', null);
      commit('SET_ORDER_ID', null);
      commit('SET_AUTHENTICATED', false);
      router.push('/');
    } catch (error) {
      console.log(error.response);
    }
  },

  changePassword: async ({ commit }, credentials) => {
    try {
      await agent.users.changePassword(credentials);
      commit('SET_PASSWORD', credentials.changedPassword);
    } catch (error) {
      console.error(error);
    }
  },

  setFirstname: ({ commit }, firstName) => {
    commit('SET_FIRST_NAME', firstName);
  },

  setLastname: ({ commit }, lastName) => {
    commit('SET_LAST_NAME', lastName);
  },

  setLoyaltyCardNumber: ({ commit }, loyaltyCardNumber) => {
    commit('SET_LOYALTY_CARD_NUMBER', loyaltyCardNumber);
  },

  setDigitalCollector: ({ commit }, option) => {
    commit('SET_DIGITAL_COLLECTOR', option);
  },

  setEmail: ({ commit }, email) => {
    commit('SET_EMAIL', email);
  },

  setEmailConfirmation: ({ commit }, emailConfirmation) => {
    commit('SET_EMAIL_CONFIRMATION', emailConfirmation);
  },

  setPassword: ({ commit }, password) => {
    commit('SET_PASSWORD', password);
  },

  setPasswordConfirmation: ({ commit }, confirmation) => {
    commit('SET_PASSWORD_CONFIRMATION', confirmation);
  },

  setPhone: ({ commit }, phone) => {
    commit('SET_PHONE', phone);
  },

  setMobile: ({ commit }, mobile) => {
    commit('SET_MOBILE', mobile);
  },

  setUserId: ({ commit }, userId) => {
    commit('SET_USER_ID', userId);
  },

  setAuthenticated: ({ commit }, status) => {
    commit('SET_AUTHENTICATED', status);
  },

  setTerms: ({ commit }, userId) => {
    commit('SET_TERMS', userId);
  },

  setPrivacyPolicy: ({ commit }, userId) => {
    commit('SET_PRIVACY_POLICY', userId);
  },
};
