import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

const generateSignature = (publicKey, timeStamp, secretKey) => {
  const hashedString = hmacSHA256(publicKey + timeStamp, secretKey);
  const encodedString = Base64.stringify(hashedString);
  return encodedString;
};

export default generateSignature;
