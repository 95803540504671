export default {
  getProduct: (state) => {
    return state.product;
  },

  getProductById: (state) => (id) => {
    return state.products.find((p) => p.id === id);
  },

  getProducts: (state) => {
    return state.products;
  },

  getProductByName: (state, getters) => (name) => {
    return state.products.find((p) => p[`name${getters.selectedLanguage}`] === name);
  },

  getProductsByName: (state) => {
    let filteredProducts = [];
    const names = ['Drinking cup', 'Stainless steel bottle'];
    names.forEach((name) => {
      filteredProducts.push(state.products.find((p) => p.name === name));
    });
    return filteredProducts;
  },
};
