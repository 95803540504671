import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/product-catalog',
    name: 'Product Catalog',
    component: () => import(/* webpackChunkName: "product-catalog" */ '../views/catalog/ProductCatalog.vue'),
  },
  {
    path: '/product-catalog/:id',
    name: 'Product Detail',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/catalog/ProductDetail.vue'),
  },
  {
    path: '/place-reservation/summary',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "order-summary" */ '../views/order/OrderSummary.vue'),
  },
  {
    path: '/place-reservation/select-store',
    name: 'Select Store',
    component: () => import(/* webpackChunkName: "select-store" */ '../views/order/StoreSelector.vue'),
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue'),
  },
  {
    path: '/customer-service',
    name: 'Customer Service',
    component: () => import(/* webpackChunkName: "customer-service" */ '../views/CustomerService.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
  },
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    component: () => import(/* webpackChunkName: "cookie-policy" */ '../views/CookiePolicy.vue'),
  },
  {
    path: '/adddirectorder',
    name: 'Add Direct Order',
    component: () => import(/* webpackChunkName: "adddirectorder" */ '../views/order/RegisterStepOne.vue'),
  },
  {
    path: '/register/step-1',
    name: 'Register Step 1',
    component: () => import(/* webpackChunkName: "register-step-1" */ '../views/order/RegisterStepOne.vue'),
  },
  {
    path: '/register/step-2',
    name: 'Register Step 2',
    component: () => import(/* webpackChunkName: "register-step-2" */ '../views/order/RegisterStepTwo.vue'),
  },
  {
    path: '/register/step-3',
    name: 'Register Step 3',
    component: () => import(/* webpackChunkName: "register-step-3" */ '../views/order/RegisterStepThree.vue'),
  },
  {
    path: '/register/thank-you',
    name: 'Thank you',
    component: () => import(/* webpackChunkName: "register-step-3" */ '../views/order/ThankYou.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/account/Login.vue'),
  },
  {
    path: '/forgot-your-password',
    name: 'Forgot your password',
    component: () => import(/* webpackChunkName: "forgot-your-password" */ '../views/account/ForgotPassword.vue'),
  },
  {
    path: '/resetpassword/:resetToken',
    name: 'Reset password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/account/ResetPassword.vue'),
  },
  {
    path: '/my-account',
    name: 'My Account',
    component: () => import(/* webpackChunkName: "my-account" */ '../views/account/MyAccount.vue'),
  },
  {
    path: '/my-order/:orderId',
    name: 'My Order',
    component: () => import(/* webpackChunkName: "my-order" */ '../views/order/MyOrder.vue'),
  },
  {
    path: '/edit-order/:orderId',
    name: 'Edit Order',
    component: () => import(/* webpackChunkName: "edit-order" */ '../views/order/EditOrder.vue'),
  },
  {
    path: '/order-changed-confirmation',
    name: 'Order Changed Confirmation',
    component: () => import(/* webpackChunkName: "edit-order" */ '../views/order/OrderChangedConfirmation.vue'),
  },
  {
    path: '/store-changed-confirmation',
    name: 'Store Changed Confirmation',
    component: () => import(/* webpackChunkName: "edit-order" */ '../views/order/StoreChangedConfirmation.vue'),
  },
  {
    path: '/cancel-notification-subscription',
    name: 'Cancel Notification Subscription',
    component: () =>
      import(/* webpackChunkName: "cancel-notification-subscription" */ '../views/CancelNotificationSubscription.vue'),
  },
  {
    path: '/*',
    name: 'Page Not Found',
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (
    (to.name === 'My Order' && !store.getters.isAuthenticated && store.getters.tokenIsExpired) ||
    (to.name === 'My Account' && !store.getters.isAuthenticated && store.getters.tokenIsExpired) ||
    (to.name === 'Edit Order' && !store.getters.isAuthenticated && store.getters.tokenIsExpired)
  )
    next({ name: 'Login' });
  else next();
});

export default router;
