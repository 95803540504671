export default {
  SET_ORDER_ID: (state, id) => {
    state.orderId = id;
  },

  SET_ORDER: (state, order) => {
    state.order = order;
  },

  SET_ORDER_LINES: (state, orderLines) => {
    state.orderLines = orderLines;
  },

  REMOVE_ORDER: (state, payload) => {
    state.order = null;
    state.orderId = payload;
  },

  // SET_SELECTED_PRODUCTS: (state, selectedProduct) => {
  //     state.selectedProducts.push(selectedProduct);
  // },

  // SET_VOUCHER: (state, voucher) => {
  //     state.voucher = voucher;
  // },

  // SET_TOTAL_PRODUCTS_AMOUNT: (state, amount) => {
  //     state.totalProductsAmount = amount;
  // },

  // SET_SELECTED_STORE: (state, selectedStore) => {
  //     state.selectedStore = selectedStore;
  // }
};
