import agent from '../../api/agent';

export default {
  fetchCookieContent: async ({ commit }) => {
    try {
      commit('SET_COOKIE_CONTENT', await agent.pages.cookie());
    } catch (error) {
      console.error(error);
    }
  },

  fetchPrivacyContent: async ({ commit }) => {
    try {
      commit('SET_PRIVACY_CONTENT', await agent.pages.privacy());
    } catch (error) {
      console.error(error);
    }
  },

  fetchTermsContent: async ({ commit }) => {
    try {
      commit('SET_TERMS_CONTENT', await agent.pages.terms());
    } catch (error) {
      console.error(error);
    }
  },
};
