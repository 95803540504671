import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const languagesModule = {
  state() {
    return {
      languages: null,
      selectedLanguage: 1,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
