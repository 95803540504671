import requests from '../requests';

const pages = {
  faq: () => requests.get('/pages/faq'),
  terms: () => requests.get('/pages/terms'),
  privacy: () => requests.get('/pages/privacy'),
  cookie: () => requests.get('/pages/cookie'),
};

export default pages;
