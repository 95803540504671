export default {
  SET_SETTINGS: (state, payload) => {
    if (payload.colorCode && CSS.supports('color', payload.colorCode)) {
      document.documentElement.style.setProperty('--primary-color', payload.colorCode);
    }
    state.settings = payload;
  },
  SET_GRANT_CONSENT: (state, payload) => {
    state.grantConsent = payload;
  },
};
