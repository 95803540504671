export default {
  getOrderId: (state) => {
    return state.orderId;
  },

  getOrder: (state) => {
    return state.order;
  },

  formattedOrderUpdatedAtDate: (state) => {
    let separator = '-';

    const url = process.env.VUE_APP_API_URL.split('/');
    const program = url[url.length - 1].toLowerCase();

    if (program.startsWith('fr')) {
      separator = '/';
    }

    const utcOrderDate = new Date(state.order.updatedAt);

    const timeDifferenceInMilliSeconds = Math.abs(utcOrderDate.getTimezoneOffset()) * 60 * 1000;

    const localDateTimeInMilliSeconds = utcOrderDate.setTime(utcOrderDate.getTime() + timeDifferenceInMilliSeconds);

    const localizedOrderDate = new Date(localDateTimeInMilliSeconds);

    return (
      localizedOrderDate.getDate() +
      separator +
      (localizedOrderDate.getMonth() + 1) +
      separator +
      localizedOrderDate.getFullYear() +
      ' ' +
      (localizedOrderDate.getHours() < 10 ? '0' + localizedOrderDate.getHours() : localizedOrderDate.getHours()) +
      ':' +
      (localizedOrderDate.getMinutes() < 10 ? '0' + localizedOrderDate.getMinutes() : localizedOrderDate.getMinutes())
    );
  },
};
